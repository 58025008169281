
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getStoreInstance, setupOrganizationsInStore } from '../store';
import { toJS } from 'mobx';
import { useRouter } from 'next/router';
export default function Index() {
    const organization = JSON.parse(localStorage.getItem('Organization'));
    const router = useRouter();
    if (organization) {
        router.push(`/dashboard`);
    }
    else {
        router.push('/signin');
    }
    return null;
}
async function getServerSideProps(context) {
    // redirect if not signed in
    const store = getStoreInstance();
    const { status } = await store.user.refreshTokens(context);
    if (status !== 200) {
        return { props: {} };
    }
    await setupOrganizationsInStore();
    if (!store.user.signedIn) {
        return { props: {} };
    }
    return { props: { organization: toJS(store.organization.selected) } };
}

    async function __Next_Translate__getServerSideProps__195fa69d5ac__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195fa69d5ac__ as getServerSideProps }
  